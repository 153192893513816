import { h } from "preact";
import { Page } from "../../../page";
import Theme, { ThemeStates } from "../../../theme";
import Navigation from "../../../navigation";
import { ArrowLeft } from "preact-feather";

export default class SettingsPage extends Page<
   { state: any },
   { vault: string }
> {
   componentWillMount() {}

   render() {
      let active = Theme.active();
      return (
         <div>
            <header class="header">
               <div class="header-icon-button" onClick={() => history.back()}>
                  <ArrowLeft height={undefined} width={undefined} />
               </div>
               <span onClick={() => Navigation.setPage("/")}>Settings</span>
               <span></span>
            </header>
            <div class="container">
               <div className="input-group">
                  <label>Select Theme: </label>
                  <select
                     class="inp"
                     onChange={(ev) =>
                        Theme.change(
                           Number((ev.target as HTMLSelectElement).value)
                        )
                     }
                  >
                     {Object.keys(ThemeStates)
                        .filter((e) => Number.isNaN(Number(e)))
                        .map((e) => (
                           <option
                              selected={ThemeStates[e] === active}
                              value={ThemeStates[e]}
                           >
                              {e.charAt(0).toUpperCase() +
                                 e.slice(1).toLowerCase()}
                           </option>
                        ))}
                     {/* <option value={ThemeStates.AUTO}>Auto</option>
                        <option value={ThemeStates.LIGHT}>Light</option>
                        <option value={ThemeStates.DARK}>Dark</option> */}
                  </select>
               </div>
               {/* <button class="btn" onClick={() => Theme.toggle()}>Toggle Dark Mode</button> */}
               <button
                  class="btn"
                  onClick={() =>
                     window.navigator.serviceWorker.controller.postMessage(
                        "message"
                     )
                  }
               >
                  Clear cache
               </button>
            </div>
         </div>
      );
   }
}
