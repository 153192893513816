import { h, Component } from 'preact';
import "./modal.scss"
import Modal from './Modal';

export class InputModal extends Modal<string> {
   constructor(protected title: string, private fieldname: string, private type: "text" | "password") {
      super();
   }

   private static IMD = class extends Component<{ modal: InputModal }, {}> {
      rand: string;
      input: HTMLInputElement;

      constructor(props) {
         super(props);
         this.rand = Math.random().toString();
      }

      componentDidMount() {
         this.input.focus();
      }

      componentWillUnmount() {
         if (this.input)
            this.input.value = "";
      }

      render() {
         return <Modal.BaseModal modal={this.props.modal}>
            <div>
               <div class="input-group">
                  <label for={this.rand}>{this.props.modal.fieldname}</label>
                  <input
                     id={this.rand}
                     type={this.props.modal.type}
                     placeholder={this.props.modal.fieldname}
                     autofocus
                     ref={elm => { this.input = elm; }}
                     onKeyDown={evt => {
                        if (evt.keyCode === 13) {
                           this.props.modal.result(this.input.value)
                        }
                     }}
                  />
               </div>
               <div class="modal-action">
                  <button class="btn btn-primary" onClick={() => {
                     this.props.modal.result(this.input.value);
                  }}>Enter</button>
               </div>
            </div>

            {/* 
            <fieldset style="border:none; min-inline-size:0;">

            </fieldset> */}
         </Modal.BaseModal>
      }
   }

   getComponent() {
      return <InputModal.IMD modal={this} />
   }
}