import { useEffect, useMemo, useState } from "preact/hooks";

export function usePromise<T>(promiseFnc: () => Promise<T>, params: any[]) {
   const promise = useMemo(promiseFnc, params);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(undefined);
   const [value, setValue] = useState(undefined);

   useEffect(() => {
      let canceled = false;

      promise
         .then((res) => setValue(res))
         .catch((err) => setError(err))
         .finally(() => setLoading(false));

      return () => {
         canceled = true;
      };
   }, [promise]);

   return [loading, error, value];
}
