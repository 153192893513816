import { h } from "preact"
import { Page } from "../page";

export interface DemoProps {
   state: any;
}

export default class DemoPage extends Page<DemoProps, {}> {
   constructor(props: DemoProps) {
      super(props);
   }

   render() {
      let elms = [];
      for (let i = 0; i < 1000; i++) {
         elms.push(<li>Hallo {i}</li>);
      }
      return <div style={{ background: this.props.state.color, marginTop: "-12px", paddingTop: "12px" }} >
         <h1>Hallo Welt</h1>
         <ul>
            {elms}
         </ul>
      </div>
   }
}