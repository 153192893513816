import { h } from "preact";

import * as EasyMDE from "easymde";
import "easymde/dist/easymde.min.css"
// import "./easymde.scss"
import { useEffect, useRef } from "preact/hooks";

export default function EasyMDEPreact(props: { value: string, onChange: (value: string) => void }) {
   const ref = useRef<HTMLTextAreaElement>();
   useEffect(() => {
      const mde = new EasyMDE({
         element: ref.current,
         initialValue: props.value
      })

      mde.codemirror.on("change", () => {
         props.onChange(mde.value());
      });

      return () => {
         mde.cleanup();
      }
   }, [ref])
   return <div style="background: white;">
      <textarea ref={ref}></textarea>
   </div>
}