import { Router } from "./Routing";
import { h } from "preact";
import NotificationsComponent from "./notifications";
import { ModalComponent } from "./modals/Modal";
import { Footer } from "./Footer";

export default function App() {
   return <div>
      <ModalComponent />
      <NotificationsComponent />
      <Router />
      <Footer />
   </div>
}