import { h, Component } from 'preact';
import Navigation from '../navigation';
import "./routing.scss"


export class Router extends Component<{}, { next?: JSX.Element, current: JSX.Element }> {
   mounted: HTMLDivElement = undefined;
   constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.state = { current: Navigation.page.page, next: undefined }
   }

   componentWillMount() {
      Navigation.pageObservable.subscribe(this.onChange)
   }

   componentWillUnmount() {
      Navigation.pageObservable.unsubscribe(this.onChange)
   }

   onChange(page: JSX.Element) {
      this.setState({ next: page, current: this.state.next || this.state.current });
   }

   render() {
      let overlay;
      if (this.state.next) {
         overlay = <div class="transition_container transition_slidein background-default" key={this.state.next.key} ref={(elm: HTMLDivElement) => {
            let lst = () => {
               if (this.state.next)
                  this.setState({ current: this.state.next, next: undefined }, () => {
                     if (this.mounted)
                        this.mounted.scrollTo({ top: 0 })
                  });
               if (elm)
                  elm.removeEventListener("animationend", lst)
            }
            if (elm)
               elm.addEventListener("animationend", lst)
         }}>
            {this.state.next}
         </div>
      }
      return <div style="position: relative; overflow-x: hidden; width: 100vw; height: calc(100vh - 2rem);">
         <div class="transition_container background-default" key={this.state.current.key} ref={elm => this.mounted = elm}>
            {this.state.current}
         </div>
         {overlay}
      </div>
   }
}