import { h, Component } from 'preact';
import "./modal.scss"
import Modal from './Modal';


export class YesNoModal extends Modal<boolean> {
   constructor(protected title: string) {
      super();
   }

   private static IMD = class extends Component<{ modal: YesNoModal }, {}> {
      constructor(props) {
         super(props);
         this.onKeyDown = this.onKeyDown.bind(this);
      }
      componentWillMount() {
         window.addEventListener("keydown", this.onKeyDown);
      }

      componentWillUnmount() {
         window.removeEventListener("keydown", this.onKeyDown);
      }

      onKeyDown(evt: KeyboardEvent) {
         if (evt.keyCode === 74 || evt.keyCode === 89) this.props.modal.result(true)
         else if (evt.keyCode === 78) this.props.modal.result(false)
      }

      render() {
         return <Modal.BaseModal modal={this.props.modal}>
            <div class="modal-action">
               <button class="btn-primary" onClick={() => {
                  this.props.modal.result(false);
               }}><span style="text-decoration: underline;">N</span>o</button>
               <button class="btn-primary" onClick={() => {
                  this.props.modal.result(true);
               }}><span style="text-decoration: underline;">Y</span>es</button>
            </div>
         </Modal.BaseModal>
      }
   }

   getComponent() {
      return <YesNoModal.IMD modal={this} />
   }
}