import { h } from "preact";
import { Page } from "../../../page";
import Notes, { IVault, BaseNote } from "../../../notes";
import Navigation from "../../../navigation";
import EntryComponent from "./Entry";
import EntryList from "./EntryList";

import "./vault.scss";
import { useState } from "preact/hooks";
import { usePromise } from "../../../hooks";

export interface VaultProps {
   state: { id: string };
   hidden: { entry: string; id: string; note: string };
}

export default function VaultPage(props: VaultProps) {
   console.log("Vault page");
   const [loading, error, vault] = usePromise(
      () =>
         Notes.getVault(
            this.props.state.id,
            Notes.getVaultKey(this.props.state.id)
         ),
      [props.state.id]
   );

   if (vault) {
      window.debug.activeVault = vault;
      window.debug.createNotes = (cnt = 10) => {
         for (let i = 0; i < cnt; i++) {
            let nt = vault.newNote();
            nt.__value = `Random Note ${i}\ With some Content ${i}`;
            vault.saveNote(nt);
         }
      };
   }

   if (loading) return <div>Loading Vault</div>;
   // Maybe return loading animation or so
   else if (error) return <div>{error.message}</div>;
   else {
      if (props.hidden?.entry) {
         return (
            <EntryComponent
               vault={vault}
               id={props.hidden.id}
               note={props.hidden.note}
            />
         );
      } else {
         return <EntryList vault={vault} />;
      }
   }
}
