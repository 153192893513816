import { h } from "preact";
import "./add_button.scss";
import { Plus } from "preact-feather";
export default function AddButton({ onClick }: { onClick: () => void }) {
   return (
      <button
         title="add button"
         class="fab btn-primary"
         onClick={() => onClick()}
      >
         <Plus width={undefined} height={undefined} />
      </button>
   );
}
