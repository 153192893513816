import { Observable } from "@hibas123/utils";

export enum MessageType {
    INFO,
    WARNING,
    ERROR,
    SUCCESS
}

export default class Notifications {
    private static messageObservableServer = new Observable<{ message: string, type: MessageType }>()
    public static messageObservable = Notifications.messageObservableServer.getPublicApi()


    public static sendNotification(message: string, type: MessageType = MessageType.INFO) {
        Notifications.messageObservableServer.send({ message, type });
    }

    public static sendInfo(message: string) {
        this.sendNotification(message, MessageType.INFO)
    }

    public static sendWarning(message: string) {
        this.sendNotification(message, MessageType.WARNING)
    }

    public static sendSuccess(message: string) {
        this.sendNotification(message, MessageType.SUCCESS)
    }

    public static sendError(error: Error | string) {
        Notifications.messageObservableServer.send({ message: typeof error === "string" ? error : error.message, type: MessageType.ERROR })
    }
}