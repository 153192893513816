import { h } from "preact";
import { Page } from "../../../page";
import VaultsPage from "../vaults/Vaults";
import Navigation from "../../../navigation";

export default class SharePage extends Page<{ state: any }, { vault: string }> {
    text: string;
    componentWillMount() {
        let { title, text, url } = this.props.state;
        let note = "";
        if (title) {
            note += title + "\n"
        }

        if (text) {
            note += text;
        }

        if (url) {
            note += "\n" + url;
        }
        this.text = note;
    }

    render() {
        return <VaultsPage state={undefined} selectVault onSelected={vault => {
            Navigation.setPage("/", undefined, undefined, true);
            Navigation.setPage("/vault", { id: vault });
            Navigation.setPage("/vault", { id: vault }, { entry: "true", note: this.text });
        }} />
    }
}
