export default class Signal {
   awaiter: (() => void)[] = [];
   sendSignal(): void {
      this.awaiter.forEach((a) => a());
      this.awaiter = [];
   }
   awaitSignal(): Promise<void> {
      return new Promise((resolve) => {
         this.awaiter.push(resolve);
      });
   }
}
