import { AwaitStore, Observable } from "@hibas123/utils";
import { readFileSync } from "fs";

const light = readFileSync(
   "node_modules/@hibas123/theme/out/light.css",
   "utf-8"
);
const dark = readFileSync("node_modules/@hibas123/theme/out/dark.css", "utf-8");

export enum ThemeStates {
   AUTO,
   LIGHT,
   DARK,
}

let themeConfig: ThemeStates = Number(localStorage.getItem("theme"));
if (Number.isNaN(themeConfig)) themeConfig = ThemeStates.AUTO;

let isDark = false;
let mediaIsDark = false;

if (window.matchMedia) {
   const mediaq = matchMedia("(prefers-color-scheme: dark)");
   mediaIsDark = mediaq.matches;
   mediaq.onchange = (ev) => {
      mediaIsDark = ev.matches;
      apply();
   };
   console.log(mediaq);
}

const DarkModeStore = new AwaitStore<boolean>(isDark);

let styleElm: HTMLStyleElement;
function apply(force?: boolean) {
   let shouldDark =
      themeConfig === ThemeStates.AUTO
         ? mediaIsDark
         : themeConfig === ThemeStates.DARK;
   if (force || shouldDark !== isDark) {
      if (styleElm) styleElm.remove();
      styleElm = document.createElement("style");
      document.head.appendChild(styleElm);
      styleElm.innerHTML = shouldDark ? dark : light;
      isDark = shouldDark;
      DarkModeStore.send(isDark);
   }
}
apply(true);

function change(state: ThemeStates) {
   themeConfig = state;
   localStorage.setItem("theme", String(themeConfig));
   apply();
}

export default {
   active: () => themeConfig,
   change: (state: ThemeStates) => change(state),
   isDark: DarkModeStore,
};
