import { h, Component } from "preact";
import Notes from "../notes";
import { RefreshCw as Refresh } from "preact-feather";
import "./footer.scss";
import Notifications from "../notifications";

export class Footer extends Component<
   {},
   { synced: boolean; syncing: boolean }
> {
   constructor(props) {
      super(props);
      this.state = { synced: false, syncing: false };
      this.onSyncedChange = this.onSyncedChange.bind(this);
      this.onSyncChange = this.onSyncChange.bind(this);
   }

   async componentWillMount() {
      Notes.syncedObservable.subscribe(this.onSyncedChange);
      Notes.syncObservable.subscribe(this.onSyncChange);
      this.setState({ synced: await Notes.isSynced() });
   }

   componentWillUnmount() {
      Notes.syncedObservable.unsubscribe(this.onSyncedChange);
      Notes.syncObservable.unsubscribe(this.onSyncChange);
   }

   onSyncChange(state: boolean) {
      console.log("sync", state);
      this.setState({ syncing: state });
   }

   onSyncedChange(state: boolean) {
      console.log("synced", state);
      this.setState({ synced: state });
   }

   onSyncClick() {
      Notes.sync().then(() => {
         Notifications.sendInfo("Finished Synchronisation");
      });
   }

   render() {
      let extrac = undefined;
      let color;
      let text;
      if (this.state.syncing) {
         color = "orange";
         text = "syncing";
         extrac = "reloading";
      } else {
         if (this.state.synced) {
            color = "green";
            text = "synced";
         } else {
            color = "red";
            text = "not synced";
         }
      }
      return (
         <footer class="elv-8">
            <span>
               <span class={extrac}>
                  <a onClick={() => this.onSyncClick()}>
                     <Refresh style="height: 1em; width: 1em;"></Refresh>
                  </a>
               </span>
               <span style={"margin-left: 8px; color:" + color}>{text}</span>
            </span>
            <span>
               Welcome <b>{Notes.name}</b>
            </span>
            <span style="color: lightgrey;">v2.0.2</span>
         </footer>
      );
   }
}
