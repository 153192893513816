import { h, Component } from "preact";

import "./notifications.scss"
import Notifications, { MessageType } from "../notifications";

export default class NotificationsComponent extends Component<{}, {
    notifications: { message: string; type: MessageType }[]
}> {

    constructor(props: any, ctx: any) {
        super(props, ctx);
        this.state = {
            notifications: [
                // { message: "test001", type: MessageType.ERROR },
                // { message: "test002", type: MessageType.WARNING },
                // { message: "test003", type: MessageType.INFO },
                // { message: "test004", type: MessageType.SUCCESS },
                // { message: "test004 lsnmfkasngkanlkgnsljkebfkjebfkabsdkfjdabsksbvhjevbakjvkhhvsfhsvdkfvsehvfksevfhsvdif\naosugfiuasgdiug", type: MessageType.SUCCESS }
            ]
        };
        this.onNotification = this.onNotification.bind(this);
    }

    componentWillMount() {
        Notifications.messageObservable.subscribe(this.onNotification);
    }

    componentWillUnmount() {
        Notifications.messageObservable.unsubscribe(this.onNotification);
    }

    onNotification(not: { message: string, type: MessageType }) {
        console.log("Got notification", not)
        let n = this.state.notifications.slice(0);
        n.push(not);
        this.setState({ notifications: n });
        setTimeout(() => {
            this.removeNot(not);
        }, 20000);
    }

    removeNot(not: { message: string }) {
        let n = this.state.notifications.filter(e => e !== not);
        this.setState({ notifications: n });
    }

    render() {
        let notifications = this.state.notifications.map(not => {
            let c;
            let m;
            switch (not.type) {
                case MessageType.ERROR:
                    c = "notifications_error"
                    m = "Error";
                    break;
                case MessageType.SUCCESS:
                    c = "notifications_success"
                    m = "Success";
                    break;
                case MessageType.INFO:
                    c = "notifications_info"
                    m = "Info";
                    break;
                case MessageType.WARNING:
                    c = "notifications_warning"
                    m = "Warning";
                    break;
            }
            return <div class={"notifications_notification " + c + " shadowed rounded"} key={not.message} onClick={() => this.removeNot(not)}>
                <h4>{m}</h4>
                <span>{not.message}</span>
            </div>
        })
        return <div class="notifications_container">
            {notifications}
        </div>
    }
}