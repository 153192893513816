export type Release = { release: () => void };

/**
 * Basic Locking mechanism for JavaScript
 *
 */
export default class Lock {
   private _locked: boolean = false;

   /**
    * Returns the state of the Locken
    *
    * @returns {boolean}
    */
   get locked(): boolean {
      return this._locked;
   }
   private toCome: (() => void)[] = [];

   constructor() {
      this.release = this.release.bind(this);
   }

   /**
    * Waits till lock is free and returns a release function
    *
    * @return {function}
    */
   async getLock(): Promise<Release> {
      if (!this._locked) return { release: this.lock() };
      else {
         return new Promise<Release>((resolve) => {
            this.toCome.push(() => {
               resolve({ release: this.lock() });
            });
         });
      }
   }

   private lock() {
      this._locked = true;
      return this.release;
   }

   private async release() {
      let next = this.toCome.shift();
      if (next) {
         next();
      } else {
         this._locked = false;
      }
   }
}
