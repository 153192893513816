
window.requestIdleCallback =
   window.requestIdleCallback ||
   function (cb) {
      var start = Date.now();
      return setTimeout(function () {
         console.log("Idle Timeout reached!");
         cb({
            didTimeout: false,
            timeRemaining: function () {
               return Math.max(0, 50 - (Date.now() - start));
            },
         });
      }, 1);
   };

window.cancelIdleCallback =
   window.cancelIdleCallback ||
   function (id) {
      clearTimeout(id as any);
   };

console.log(window.requestIdleCallback);

window.debug = {};

import { h, render } from "preact";
import App from "./components/App";
import "@hibas123/theme/out/base.css";
import "./index.scss";
import Navigation from "./navigation";
import VaultsPage from "./components/routes/vaults/Vaults";
import { Page } from "./page";

import Notes from "./notes";
import DemoPage from "./components/demo";
import VaultPage from "./components/routes/vault/Vault";
import SharePage from "./components/routes/share/Share";
import Notifications from "./notifications";
import Error404Page from "./components/routes/404";
import SettingsPage from "./components/routes/settings/Settings";
window.debug.notes = Notes;

import Theme from "./theme";

console.log("Dark mode:", Theme.active);

(async () => {
   // Initialize notes provider
   if (Notes.loginRequired()) {
      let url = new URL(location.href);
      let code = url.searchParams.get("code");

      if (code) {
         let err = await Notes.getToken(code);
         if (err) {
            Notifications.sendError("Login failed: " + err);
            return Notes.login();
         } else {
            window.history.replaceState(
               null,
               document.title,
               "/" + window.location.hash
            );
         }
      } else {
         return Notes.login();
      }
   }

   await Notes.start();

   if (window.navigator.storage && navigator.storage.persist) {
      navigator.storage
         .persisted()
         .then((has) => (has ? true : navigator.storage.persist()))
         .then((is) => {
            console.log("Persistant Storage:", is);
         });
   }

   Navigation.default = VaultsPage as typeof Page;
   Navigation.addPage("/vault", VaultPage as any as typeof Page);
   Navigation.addPage("/demo", DemoPage as typeof Page);
   Navigation.addPage("/share", SharePage as typeof Page);
   Navigation.addPage("/settings", SettingsPage as typeof Page);
   Navigation.addPage("/404", Error404Page);
   const trad = window.location.pathname;
   if (trad && trad !== "/" && trad !== "") {
      let p: any = {};
      new URL(window.location.href).searchParams.forEach(
         (val, key) => (p[key] = val)
      );
      Navigation.setPage(trad, p, undefined, true);
      // window.location.href = "/#/" + trad;
   }
   Navigation.start();

   render(<App />, document.body, document.getElementById("app"));
})();


if (navigator.serviceWorker.controller) {
   if (localStorage.getItem("debug")) {
      console.warn(
         "Debuggung and service worker found, make shure to clear cache!"
      );
   }
   console.log("active service worker found, no need to register");
} else {
   if (localStorage.getItem("debug")) {
      console.warn("Disabling Service Worker in debug mode!");
   } else {
      // Register the ServiceWorker
      navigator.serviceWorker
         .register(new URL("serviceworker.js", import.meta.url), {
            scope: "/",
         })
         .then(function (reg) {
            console.log(
               "Service worker has been registered for scope:" +
               reg.scope
            );
            navigator.serviceWorker.controller.addEventListener(
               "cleared_cache",
               (evt) => {
                  console.log(evt);
               }
            );
         });
   }
}